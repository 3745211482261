<template>
  <div class="row mt-3 mb-3">
    <div class="col-md-9">
      <template v-if="allRecordsCount > 0">
        <div class="btn-group me-2">
          <button
            type="button"
            class="btn btn-success cursor-pointer fs-10"
            @click="setAllRead()"
          >
            <i class="fs-4 bi bi-envelope-check-fill fa-2x"> </i>
            {{
              $t(
                "AccountNotifications.MarkAllAsRead",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
        <div class="btn-group me-2">
          <button
            type="button"
            class="btn btn-secondary cursor-pointer fs-10"
            @click="setAllUnRead()"
          >
            <i class="fs-4 bi bi-envelope-dash-fill fa-2x"> </i>
            {{
              $t(
                "AccountNotifications.MarkAllAsUnread",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </template>
      <template v-else>
        <div class="btn-group me-2">
          <button
            type="button"
            class="btn btn-success"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="
              $t(
                'AccountNotifications.MarkAllAsReadWarningMessage',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
          >
            <i class="fs-4 bi bi-envelope-check-fill fa-2x"> </i>
            {{
              $t(
                "AccountNotifications.MarkAllAsRead",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
        <div class="btn-group me-2">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="
              $t(
                'AccountNotifications.MarkAllAsUnreadWarningMessage',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
          >
            <i class="fs-4 bi bi-envelope-dash-fill fa-2x"> </i>
            {{
              $t(
                "AccountNotifications.MarkAllAsUnread",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </template>
    </div>
    <div class="col-md-3">
      <div class="btn-group float-end">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle text-capitalize btn-dark"
            type="button"
            :title="buttonName"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <span
              ><i class="bi bi-filter"></i>
              {{ buttonName }}
            </span>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end fs-6 action-button-top-block-list-groups filter-dropdowns p-0"
            data-popper-placement="bottom-end"
          >
            <div v-for="(item, index) in buttons" :key="index">
              <li v-if="item.visible" class="mb-1 mt-1 text-capitalize">
                <a
                  type="button"
                  class="dropdown-item cursor-pointer"
                  @click="filter(item.typeId)"
                  ><i
                    v-if="item.typeId == 1"
                    class="fs-4 far bi bi-envelope-fill fa-2x text-secondary"
                  ></i>
                  <i
                    v-if="item.typeId == 2"
                    class="fs-4 bi bi-envelope-open-fill fa-2x text-success"
                  ></i>
                  <i
                    v-if="item.typeId == 0"
                    class="fs-4 far bi bi-envelope-check-fill fa-2x text-success"
                  ></i>
                  {{ item.value }}
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <StaticGrid
    v-if="isRequestFinished"
    ref="grid"
    :settings="gridSettings"
    :columns="gridColumns"
  />
  <AreYouSureModal :warningMessage="warningMessage" :data="warningData" />
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import StaticGrid from "@/components/devexpress/grid/static/Grid.vue";
import AreYouSureModal from "@/views/crafted/account/AreYouSureModal.vue";
import { showModal } from "@/core/helpers/dom";
import $ from "jquery";
export default {
  name: "AccountNotifications",
  components: {
    StaticGrid,
    AreYouSureModal,
  },
  data() {
    return {
      buttons: [
        {
          typeId: 1,
          value: this.$t(
            "AccountNotifications.Unreads",
            {},
            { locale: this.$store.state.activeLang }
          ),
          visible: false,
        },
        {
          typeId: 2,
          value: this.$t(
            "AccountNotifications.Reads",
            {},
            { locale: this.$store.state.activeLang }
          ),
          visible: true,
        },
        {
          typeId: 0,
          value: this.$t(
            "AccountNotifications.All",
            {},
            { locale: this.$store.state.activeLang }
          ),
          visible: true,
        },
      ],
      buttonName: this.$t(
        "AccountNotifications.Unreads",
        {},
        { locale: this.$store.state.activeLang }
      ),
      oldTypeId: 1,
      warningData: {},
      warningMessage: "",
      allRecordsCount: 0,
      actionName: "rws-NotificationController-List",
      notifications: [],
      isRequestFinished: false,
      gridSettings: {
        action: "AccountNotifications",
        requestUrl: "rws-NotificationController-List?statusTypeId=1",
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        isCommandColumnHide: true,
        isPublicIdColumnHide: true,
        allowSearchPanel: false,
        allowExcelExport: false,
        allowPaging: true,
        changeOrderUrl: "",

        autoOrderProcess: true,
      },
      gridColumns: [
        {
          text: this.$t(
            "AccountNotifications.Status",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "statusTypeId",
          type: "string",
          visible: true,
          width: 50,
          template: "notificationStatusTypeTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "AccountNotifications.NotificationSubject",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "notificationSubject",
          type: "string",
          visible: true,
          width: 0,
          template: "notificationTypeTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "AccountNotifications.BusinessRuleActionName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "businessRuleActionName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    setBreadcrumb() {
      setCurrentPageBreadcrumbs(
        this.$t(
          "AccountNotifications.Title",
          {},
          { locale: this.$store.state.activeLang }
        ),
        []
      );

      this.$root.changeDocumentTitle(
        this.$t(
          "AccountNotifications.Title",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
    },
    getNotifications(notificationTypeId) {
      this.$appAxios
        .get(
          `rws-NotificationController-List?statusTypeId=` + notificationTypeId
        )
        .then((response) => {
          this.notifications = response.data.items;
          this.isRequestFinished = true;
          if (notificationTypeId == 0) {
            this.allRecordsCount = this.notifications.length;
          }
        });
    },
    filter(typeId) {
      this.buttonName = this.buttons.filter((x) => x.typeId == typeId)[0].value;

      var foundIndex = this.buttons.findIndex((x) => x.typeId == typeId);
      var oldIndex = this.buttons.findIndex((x) => x.typeId == this.oldTypeId);
      this.buttons[foundIndex].visible = false;
      this.buttons[oldIndex].visible = true;
      this.oldTypeId = typeId;

      var searchUrl = `${this.actionName}?statusTypeId=${typeId}`;

      this.gridSettings.requestUrl = ""; //trigger watch parameter
      this.gridSettings.requestUrl = searchUrl;
    },
    setAllRead() {
      this.warningData.modalTypeId = 1;
      this.warningData.requestUrl = `rws-NotificationController-SetAllRead`;
      this.warningData.recordCount = this.allRecordsCount;
      this.warningMessage = this.$t(
        "AreYouSureModal.DescriptionSetAllRead",
        {},
        { locale: this.$store.state.activeLang }
      );
      showModal(document.getElementById("areYouSureModal"));
    },
    setAllUnRead() {
      this.warningData.modalTypeId = 2;
      this.warningData.requestUrl = `rws-NotificationController-SetAllUnRead`;
      this.warningData.recordCount = this.allRecordsCount;
      this.warningMessage = this.$t(
        "AreYouSureModal.DescriptionSetAllUnRead",
        {},
        { locale: this.$store.state.activeLang }
      );
      showModal(document.getElementById("areYouSureModal"));
    },
  },
  mounted() {
    this.setBreadcrumb();
    this.getNotifications(0);
    //this.getNotifications(1);
  },
};
</script>
