<template>
  <div
    class="modal fade"
    id="areYouSureModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`areYouSureModal_Label`">
            <i class="bi bi-trash"></i>
            {{
              $t(
                "AreYouSureModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            v-if="!this.buttonDisabled"
            @click="clear"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <span v-if="errors.length == 0" class="fs-5"
            ><i class="bi bi-exclamation-circle"></i>
            {{ data.recordCount + warningMessage }}
          </span>
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="modal-footer"
          :class="
            errors.length == 0
              ? 'justify-content-between'
              : 'justify-content-right'
          "
        >
          <div v-if="errors.length == 0" :class="{ shake: shakeAnimate }">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="IConfirmButton"
                name="IConfirmButton"
                :checked="confirmDeletion"
                v-model="confirmDeletion"
                :disabled="buttonDisabled"
              />
              <label
                class="form-check-label ms-2 fw-bold delete-datacheck-label fs-5 no-selection"
                id="IConfirmButton"
                @click="confirmDeletion = !confirmDeletion"
                >{{
                  $t(
                    "AreYouSureModal.IConfirm",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-success btn-sm me-2 mark-record"
              v-if="errors.length == 0 && data.modalTypeId == 1"
              :disabled="buttonDisabled"
              @click="markRecords"
            >
              <span>
                <i class="fs-4 bi bi-envelope-check-fill fa-2x"></i>
                {{
                  $t(
                    "AreYouSureModal.SetButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-sm me-2 mark-record"
              v-if="errors.length == 0 && data.modalTypeId == 2"
              :disabled="buttonDisabled"
              @click="markRecords"
            >
              <span>
                <i class="fs-4 bi bi-envelope-dash-fill fa-2x"></i>
                {{
                  $t(
                    "AreYouSureModal.SetButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm btn-close-modal"
              @click="clear"
              v-if="!this.buttonDisabled"
              data-bs-dismiss="modal"
            >
              <i class="bi bi-x"></i>
              {{
                $t(
                  "AreYouSureModal.Close",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hideModal } from "@/core/helpers/dom";
import $ from "jquery";
export default {
  name: "AreYouSureModal",
  data() {
    return {
      errors: [],
      shakeAnimate: false,
      confirmDeletion: false,
      buttonDisabled: false,
      submitButtonHtml: String.format(
        '<span class="loading-block d-flex justify-content-center align-items-center"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="ms-2">{0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      deleteResponse: null,
    };
  },
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    warningMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    shake() {
      this.shakeAnimate = true;
      setTimeout(() => {
        this.shakeAnimate = false;
      }, 3000);
    },
    clear: function () {
      this.confirmDeletion = false;
      this.buttonDisabled = false;
      this.errors = [];
    },
    success: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirmDeletion = false;
      hideModal(document.getElementById("areYouSureModal"));
    },
    error: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirmDeletion = false;
    },
    markRecords: function () {
      if (
        !this.confirmDeletion ||
        String.isNullOrWhiteSpace(this.data.requestUrl)
      ) {
        this.shake();
        return;
      }

      this.buttonDisabled = true;
      this.errors = [];

      var button = $(String.format(".mark-record")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      let self = this;
      self.$appAxios
        .post(this.data.requestUrl)
        .then((response) => {
          const result = response.data;

          if (result.isOk) {
            self.success(firstSpan, loadingBlock);
            location.reload();
          } else {
            self.error(firstSpan, loadingBlock);
            self.errors.push(result.message);
          }
        })
        .catch(function (error) {
          self.errors.push(error);
        });
    },
  },
};
</script>
